import { Button, TextInput } from "@mantine/core";
import { useForm } from "@mantine/form";
import useUsersMutationQuery from "api/user/useUserMutation";
import { useLocalStorage } from "context/LocalStorageProvider";
import { useModal } from "context/ModalStackManager";
import { EquipmentAutoComplete } from "features/autoComplete/equipment/equipment-autoComplete";
import { FormButtonBox } from "../FormButtonBox";
import { FormMain } from "../FormMain";
import { FormWrapper } from "../FormWrapper";

const User = Object.assign(FormMain, {
  Button: Button,
  Input: TextInput,
  Wrapper: FormWrapper,
  ButtonBox: FormButtonBox,
});

export const UserUpdate = () => {
  const { closeModal } = useModal();
  const { userId, userName, name, equipmentCode, setEquipmentCode, setWorkId } = useLocalStorage();

  const form = useForm({
    initialValues: {
      id: userId, // pk
      name: name, // 이름
      username: userName, // 아이디
      equipmentCode: equipmentCode //설비코드
    },
  });

  const { mutate: updateMutate } = useUsersMutationQuery("update");

  const onSubmit = () => {
    try {
      updateMutate(
        {
          userId: form.values.id as number,
          usersUserIdDeleteRequest: {
            name: form.values.name,
            username: form.values.username,
            equipmentCode: form.values.equipmentCode,
          },
        },
        {
          onSuccess: (res) => {
            if (res.status === 200) {
              alert("사용자 수정 성공");
              setWorkId(undefined)
              setEquipmentCode(form.values.equipmentCode);
              closeModal({});
            }
          },
        }
      );
    } catch (e) {
      alert("실패");
    }
  };

  return (
    <User.Wrapper>
      <User.Input
        size="xl"
        label="사용자 ID"
        defaultValue={form.values?.username}
        disabled
        styles={{ label: { fontSize: "0.9em" } }}
      />
      <User.Input
        size="xl"
        label="사용자 이름"
        defaultValue={form.values?.name}
        disabled
        styles={{ label: { fontSize: "0.9em" } }}
      />
      <EquipmentAutoComplete
        size="xl"
        label="설비"
        defaultValue={form.values.equipmentCode}
        styles={{ label: { fontSize: "0.9em" } }}
        {...form.getInputProps("equipmentCode")}
      />
      <User.ButtonBox>
        <User.Button color="gray" size="lg" onClick={closeModal}>
          취소
        </User.Button>
        <User.Button onClick={onSubmit} size="lg">
          등록
        </User.Button>
      </User.ButtonBox>
    </User.Wrapper>
  );
};
