import { useSocket, useSocketEvent } from "socket.io-react-hook";
import { UseGetEquipmentCounterProps } from "./types";

export const useGetEquipmentCounter = ({
  equipmentCode,
  enabled = true,
}: UseGetEquipmentCounterProps): {
  equipmentCounter: string;
  equipmentLimitCounter: string;
  isLimitHit: boolean;
} => {
  const { socket, error } = useSocket(
    process.env.REACT_APP_BASE_URL as string,
    {
      autoConnect: true,
      enabled: enabled,
    }
  );

  const roomName = equipmentCode ? `EC_${equipmentCode}` : "";

  if (roomName) {
    socket.emit(
      "call",
      "socket.join",
      { room: roomName },
      async (err: any, res: any) => {
        if (err) {
          console.error(err);
        }
      }
    );
  }

  const { lastMessage = {} } = useSocketEvent(socket, roomName);

  return {
    equipmentCounter: lastMessage.equipmentCounter ?? "0",
    equipmentLimitCounter: lastMessage.equipmentLimitCounter,
    isLimitHit: lastMessage.isLimitHit,
  };
};
