import { WorkLogsGet200ResponseRowsInnerWorkLogTypeEnum } from "@sizlcorp/sizl-api-document/dist/models";

export const WorkLogTypeKoreanEnum = {
    INPUT: "투입",
    CONSUME: "소모",
    CONSUME_IDENTIFIED: "식별된 소모",
    DEFECT: "불량",
    REWORK: "리워크",
    START: "시작",
    END: "종료",
    PRODUCTION: "생산",
    TIME_TRACKING_START: "작업시작",
    TIME_TRACKING_END: "작업종료",
    TIME_TRACKING_PAUSE: "작업지연",
    TIME_TRACKING_RESUME: "작업재개",
    PROGRESS: "",
    PERFORMANCE: "실적",
    LOSS: "",
    CANCEL_START: "",
    CANCEL_INPUT: "투입 취소",
    CANCEL_PROGRESS: "",
    CANCEL_LOSS: "손실 취소",
    CANCEL_CONSUME: "",
    CANCEL_PERFORMANCE: "성능 취소",
    CANCEL_PRODUCTION: "",
    CANCEL_DEFECT: "",
    CANCEL_REWORK: "",
    CANCEL_CONSUME_IDENTIFIED: "",
    CANCEL_END: "",
    CANCEL_WIP_DECREASE: "",
    WIP_INCREASE: "",
    WIP_DECREASE: "",
    RESTORE: "",
    SPLIT_LOT: "",
};

export const WorkLogTypeColorEnum = {
    INPUT: "gray",
    CONSUME: "green",
    CONSUME_IDENTIFIED: "cyan",
    DEFECT: "red",
    REWORK: "pink",
    START: "grape",
    END: "violet",
    PRODUCTION: "indigo",
    TIME_TRACKING_START: "lime",
    TIME_TRACKING_END: "yellow",
    TIME_TRACKING_PAUSE: "orange",
    TIME_TRACKING_RESUME: "teal",
    PROGRESS: "",
    PERFORMANCE: "blue",
    LOSS: "",
    CANCEL_START: "",
    CANCEL_INPUT: "red",
    CANCEL_PROGRESS: "",
    CANCEL_LOSS: "gray",
    CANCEL_CONSUME: "",
    CANCEL_PERFORMANCE: "gray",
    CANCEL_PRODUCTION: "",
    CANCEL_DEFECT: "",
    CANCEL_REWORK: "",
    CANCEL_CONSUME_IDENTIFIED: "gray",
    CANCEL_END: "",
    CANCEL_WIP_DECREASE: "",
    WIP_INCREASE: "",
    WIP_DECREASE: "",
    RESTORE: "",
    SPLIT_LOT: "",
};

export function getKoreanWorkLogType(type: keyof typeof WorkLogsGet200ResponseRowsInnerWorkLogTypeEnum): string {
    return WorkLogTypeKoreanEnum[type] || "알 수 없는 유형";
}

export function getColorOfLogType(type: keyof typeof WorkLogsGet200ResponseRowsInnerWorkLogTypeEnum): string {
    return WorkLogTypeColorEnum[type] || "gray"; // 알 수 없는 유형에 대해서는 회색을 반환합니다.
}