import { UseGetEquipmentCounterBySettingProps } from "./types";
import { useGetEquipmentCounter } from "./useGetEquipmentCounter";

export const useGetEquipmentCounterBySetting = (
  params: UseGetEquipmentCounterBySettingProps
) => {
  const { workData } = params;

  const isGetCounter = canFetchCounter({
    id: workData?.id,
    activeWorkId: workData?.equipment?.activeWorkId,
  });

  const enabledGetCounter =
    (workData?.trackingStatus === "WORKING" || workData?.trackingStatus === "PAUSED") &&
    isGetCounter &&
    workData?.externalCounterSetting !== "HANDWRITING";

  const { equipmentCounter, equipmentLimitCounter, isLimitHit } =
    useGetEquipmentCounter({
      equipmentCode: workData?.equipment?.code as string,
      enabled: enabledGetCounter,
    });

  return {
    equipmentCounter,
    equipmentLimitCounter,
    isLimitHit,
  };
};

const canFetchCounter = ({ id, activeWorkId }: any) => {
  return id === activeWorkId;
};
