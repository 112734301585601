import styled from "@emotion/styled";
import { Text } from "@mantine/core";
import { ItemsGet200ResponseRowsInnerLotsInnerInventoriesInner } from "@sizlcorp/sizl-api-document/dist/models";
import dayjs from "dayjs";
import { setToLocaleString } from "utils/unitMark";
interface InventoryRowProps {
  data: ItemsGet200ResponseRowsInnerLotsInnerInventoriesInner;
}

export const InventoryRow = (params: InventoryRowProps) => {
  const {
    itemCode,
    lot,
    locationCode,
    quantity,
    increaseQuantity,
    decreaseQuantity,
    deltaQuantity,
    closedQuantity,
    closedAt,
    unitText,
  } = params.data;

  return (
    <InventorysRowWrapper color="transparent">
      <Td seq={1}>
        <Text c={"#FFFFFF"} size={"lg"} align="left" w={"100%"}>
          {itemCode}
        </Text>
      </Td>
      <Td seq={2}>
        <Text c={"#FFFFFF"} size={"lg"} align="left" w={"100%"}>
          {lot?.name}
        </Text>
      </Td>
      <Td seq={3}>
        <Text c={"#FFFFFF"} size={"lg"} align="left" w={"100%"}>
          {lot?.expiration
          ? dayjs(lot?.expiration).format("YYYY-MM-DD")
          : "-"}
        </Text>
      </Td>
      <Td seq={4}>
        <Text c={"#FFFFFF"} size={"lg"} align="left" w={"100%"}>
          {locationCode}
        </Text>
      </Td>
      <Td seq={1}>
        <Text c={"#FFFFFF"} size={"lg"} align="right" w={"100%"}>
          {setToLocaleString(quantity)} {unitText}
        </Text>
      </Td>
      <Td seq={4}>
        <Text c={"#FFFFFF"} size={"lg"} align="right" w={"100%"}>
          {closedQuantity !== null ? (setToLocaleString(closedQuantity) + " " + unitText) : ""} 
        </Text>
      </Td>
      <Td seq={4}>
        <Text c={"#FFFFFF"} size={"lg"} align="left" w={"100%"}>
          {closedAt}
        </Text>
      </Td>
      <Td seq={5}>
        <Text c={"#FFFFFF"} size={"lg"} align="right" w={"100%"}>
          {setToLocaleString(increaseQuantity)} {unitText}
        </Text>
      </Td>
      <Td seq={6}>
        <Text c={"#FFFFFF"} size={"lg"} align="right" w={"100%"}>
          {setToLocaleString(decreaseQuantity)} {unitText}
        </Text>
      </Td>
      <Td seq={1}>
        <Text c={"#FFFFFF"} size={"lg"} align="right" w={"100%"}>
          {setToLocaleString(deltaQuantity)} {unitText}
        </Text>
      </Td>
      
    </InventorysRowWrapper>
  );
};

const InventorysRowWrapper = styled.tr<{ color: string }>`
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  background-color: ${(props) => props.color};
  gap: 0px;
  align-items: flex-start;
  align-self: stretch;
  flex-shrink: 0;
  position: relative;
  border-bottom: 1px solid white;
  width: 100%;

  &:hover {
    background-color: #999;
    opacity: 0.8;
  }
`;
const Td = styled.td<{ seq?: number; flex? : number }>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 10px;
  flex : ${(props) => (props.flex ? props.flex : 1)};
  align-self: stretch;
`;
