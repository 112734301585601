import React, { createContext, useContext, useEffect, useState } from 'react';

// LocalStorageContext를 생성합니다.
const LocalStorageContext = createContext<{
    workId?: any;
    operationCode?: any;
    userId?: any
    userName?: any
    name?: any
    equipmentCode?: any
    managementRole?: any
    setWorkId: (value: any) => void;
    setUserId: (value: any) => void;
    setUserName: (value: any) => void;
    setName: (value: any) => void;
    setEquipmentCode: (value: any) => void;
    setOperationCode: (value: any) => void;
    setManagementRole: (value: any) => void;
}>({
    workId: null,
    operationCode: null,
    userId: null,
    userName: null,
    name: null,
    equipmentCode: null,
    managementRole: null,
    setWorkId: () => { },
    setUserId: () => { },
    setUserName: () => { },
    setName: () => { },
    setEquipmentCode: () => { },
    setOperationCode: () => { },
    setManagementRole: () => { }
});

// LocalStorageProvider 컴포넌트를 정의합니다.
export const LocalStorageProvider: React.FC<{
    initialId?: any,
    initialOperationCode?: any,
    initialUserId?: any
    initialUserName?: any
    initialName?: any
    initialEquipmentCode?: any
    initialManagementRole?: any
    children?: any
}> = ({
    initialId,
    initialOperationCode,
    initialUserId,
    initialUserName,
    initialName,
    initialEquipmentCode,
    children,
    initialManagementRole
}: any) => {

        const [equipmentCode, setEquipmentCode] = useState(() => {
            try {
                const item = window.localStorage.getItem('equipmentCode');
                return item ? item : initialEquipmentCode;
            } catch (error) {
                return initialEquipmentCode;
            }
        })

        const [userId, setUserId] = useState(() => {
            try {
                const item = window.localStorage.getItem('userId');
                return item ? item : initialUserId;
            } catch (error) {
                return initialUserId;
            }
        });


        const [userName, setUserName] = useState(() => {
            try {
                const item = window.localStorage.getItem('userName');
                return item ? item : initialUserName;
            } catch (error) {
                return initialUserName;
            }
        });

        const [name, setName] = useState(() => {
            try {
                const item = window.localStorage.getItem('name');
                return item ? item : initialName;
            } catch (error) {
                return initialName;
            }
        });
        const [workId, setWorkId] = useState(() => {
            try {
                const item = window.localStorage.getItem('workId');
                return item ? item : initialId;
            }
            catch (error) {
                return initialId;
            }
        });

        const [operationCode, setOperationCode] = useState(() => {
            try {
                const item = window.localStorage.getItem('operationCode');
                return item ? item : initialOperationCode;
            } catch (error) {
                return initialOperationCode;
            }
        });

        const [managementRole, setManagementRole] = useState(() => {
            try {
                const item = window.localStorage.getItem('managementRole');
                return item ? item : initialManagementRole;
            } catch (error) {
                return initialManagementRole;
            }
        });



        useEffect(() => {
            window.localStorage.setItem('workId', workId);
            window.localStorage.setItem('equipmentCode', equipmentCode);
            window.localStorage.setItem('operationCode', operationCode);
            window.localStorage.setItem('userId', userId);
            window.localStorage.setItem('userName', userName);
            window.localStorage.setItem('managementRole', managementRole);
            window.localStorage.setItem('name', name);

        }, [workId, operationCode, userId, userName, equipmentCode, managementRole, name]);

        return (
            <LocalStorageContext.Provider
                value={{ workId, operationCode, equipmentCode, userId, userName, managementRole, name, setName, setWorkId, setOperationCode, setUserId, setUserName, setEquipmentCode, setManagementRole }}>
                {children}
            </LocalStorageContext.Provider>
        );
    };

// useLocalStorage 훅을 정의합니다.
export const useLocalStorage = () => {
    return useContext(LocalStorageContext);
};
